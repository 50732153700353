import { Link } from 'react-scroll'
import { Box } from '../Styled/Elements'
import { HeaderContainer } from './Styled'
import { NavDataConfig } from '../data/Data'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BsJustify, BsX } from "react-icons/bs"
import { Container, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { Phone, Logo, Twitter, Instagram, Linkedin, LogoAr } from '../Styled/AllImages'
import { ButtonWithIcon } from '../Styled/CustomElements'
import ChangeButton from '../../Languages/ChangeButton'

const Header = () => {
    const navigate = useNavigate()
    const WhatsApp = () => {
        const phoneNumber = '+966533046533';
        const message = 'Hello, Al faif';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir() === 'rtl';
    const [isSticky, setIsSticky] = useState(false);
    const [toggle, setToggle] = useState(false);

    const handleToggle = () => {
        setToggle(!toggle);
        const element = document.querySelector(".nav-bar-list");
        element.classList.toggle("showToggle");
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const socialIcon = [
        {
            img: <Twitter />,
            link: 'https://x.com/altaif2024?s=21&t=Wq0RMCoJloRUNeSTcfLPrg',
        },

        {
            img: <Instagram />,
            link: 'https://www.instagram.com/eltayef.sa/profilecard/?igsh=MWZ4c3Y1dmw0dXpreg==',
        },
        {
            img: <Linkedin />,
            link: 'https://www.snapchat.com/add/eltayef.sa?share_id=1vRxAMusgk4&locale=ar-AE',
        },

    ];

    return (

        <HeaderContainer className={`border-bottom-line ${isSticky ? 'sticky' : ''} container-fluid`}>
            <Container>
                <Row>
                    <Box className='nav-bar'>
                        <Box className="logo" onClick={() => navigate('/')}>
                            {isRTL ? <LogoAr /> : <Logo />}
                        </Box>
                        <Box className='nav-bar-list'>
                            {NavDataConfig.map((item, key) => (
                                <Box key={key}>
                                    <Link to={item.to} spy={true} smooth={true} offset={-60} duration={500} activeClass="active" onClick={handleToggle}>{t(item.label)}</Link>
                                </Box>
                            ))}
                        </Box>
                        <Box className='d-flex align-items-center gap-3'>
                            <ButtonWithIcon clName='header-btn d-flex gap-2' icon={Phone} label='+966533046533' onClick={WhatsApp} />
                            <Box className='d-flex align-items-center gap-3 social-icon'>
                                {
                                    socialIcon.map((list, key) => (
                                        <Box key={key}>
                                            <a href={list.link} target="_blank" rel="noopener noreferrer">
                                                {list.img}
                                            </a>
                                        </Box>
                                    ))
                                }
                            </Box>
                            <span className="toggleBtn" onClick={handleToggle}>
                                {toggle ? <BsX size={30} /> : <BsJustify size={30} />}
                            </span>
                        </Box>
                    </Box>
                </Row>
            </Container>
            <ChangeButton />
        </HeaderContainer>

    )
}


export default Header
