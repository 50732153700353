import i18n from './Languages/INEXT';
import React, { useEffect } from 'react'
import { Box } from './components/Styled/Elements';
import { privateRoute } from './route/PrivateRoute';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import ErrorPage from './components/Page404/ErrorPage';

import CursorPointer from './components/cursor/CursorPointer';

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scroll({ top: 0, behaviour: 'smooth' })
  }, [location]);
  const [cookies, setCookie] = useCookies(['lang']);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCookie('lang', lng, { path: '/' }); // Set cookie with the language preference
  };

  return (
    <Box className='main-artical'>
      <Routes>
        {privateRoute.map((list, key) => (
          <Route to={key} {...list} />
        ))}
      </Routes>
    </Box>
  );
}

export default App;
