import styled from "styled-components";
import { ServiceBg, ServiceTab, } from "../../Styled/AllImages";


export const ServiceWrapper = styled.section`
    position:relative;
    background-image: url(${ServiceTab});
    background-position: top !important;
    background-size: contain !important;
    
.product-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:20px;
    padding: 0;
    width: 50%;
    margin: 30px 0;
}
.product-wrapper-tabs{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text);
    width: 100%;
    gap:20px;
    transition: transform 0.4s ease-in-out;
    &>span{
        padding:10px 6px;
        width: 40%;
        text-align:center;
        border-radius:8px;
        transition: all 0.4s ease-in-out;
        background-color: var(--white);
        box-shadow: 0px 8px 10px rgba(15, 54, 149, 0.1);
        color: var(--lightBlue);
    }
    &>span:hover, & .activeTab{
        background-color: var(--lightBlue);
        color: var(--white);
        cursor:pointer;
    } 
        & .activeTab{
        //  font-family:var(--bold);
         font-family: ${props => props.direction === 'rtl' && props.family ? props.family : "var(--bold)"},
                ${props => props.direction === 'ltr' && props.family ? props.family : "var(--arBold)"};
        }
}
.tab-active-row{
    row-gap:25px;
}
.active-tab-content{
    position:relative;
    height: 210px;
    border-radius: 12px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
   
    &::before{
        content:"";
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:210px;
        background-image: url(${ServiceBg});
        border-radius: 12px;
        background-size: 100% 100%;
    }
 }

 .service-text {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    z-index: 1;
    position: relative;
    padding: 0 8px 20px;
    text-align: center;
}

.Operations-box{

    &>div{
         position:relative;
         height: 100%;
         border-radius: 12px;
         background-repeat: no-repeat !important;
        background-position: center !important;
         background-size: cover !important;
           
         &::before{
            content:"";
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background-image: url(${ServiceBg});
            border-radius: 12px;
            background-size: 100% 100%;
        }
    }

    & .service-bg{
        height: 100%;
        border-radius: 12px;
        background-position: center !important;
        background-size: cover!important;
        background-repeat: no-repeat !important;
    }
}

.Operations-container{
    display: grid;
    gap:15px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.item-1 {
    grid-column: 1 / 1;
    grid-row: 1 / 3;
    height:430px;
}
.item-4{
    grid-column: 4 / 4;
    grid-row: 1 /3;
    height:430px;
}
.item-5{
    grid-column: 2 / 4;
    grid-row: 2 /3;
    border-radius: 12px;
}


`