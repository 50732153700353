import styled from "styled-components";
import { ExperianceBg } from "../../Styled/AllImages";

export const ChooseWapper = styled.section`
    background-image: url(${ExperianceBg});
    background-position: top !important;
    background-size: contain !important;

.Choose-digital{
    position: relative;
    padding: 40px 20px 0px 30px;
    height: 100%;
}
.project-wapper{
   background: white;
    width: 320px;
    padding: 12px 15px ;
    border-radius: 12px;
}
.compaire{
    display: flex;
    align-items: flex-start;
    gap: 12px;
    &>div{
        width:100%;
    }
}
    .experiance-detail{
        display:flex;
        flex-wrap: wrap;
        gap:25px;
        margin-top:20px;
    }
    .experiance-heading{
        width:50%;
            line-height: 1.3;
    }
`
export const WorkerWraper = styled.div`
    position:relative;
    & >span{
        position:relative;
        width:100%;
    }
    
    .worker-logo{
        position: absolute;
        bottom:-5px;
        left:0;
        right: 100px;
        margin:auto;
    }
    .workers-man{
        position:relative;
        & >span{
            position: absolute;
            bottom: -226px;
            right: 0px;
            // width: 610px;
        }
    }

`