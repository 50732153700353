import styled from "styled-components";

export const ClientWrapper = styled.section`
.client-wrap{
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 35px;
    padding: 30px;
}
.client-content{
    display: flex;
    align-items: center;
    justify-content: center;
}

.client-content{
& div{
    width: 120px;
    height: 80px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
        & svg{
             width: 100%;
             height: 100%;
             object-fit: contain;
        }
    }
}

`
