import React from 'react'
import { BannerWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BannerHeading, Box, SpanStyled, TextStyled } from '../../Styled/Elements'
import { ArrowRight } from '../../Styled/AllImages'
import { ContactButton } from '../../Styled/CustomElements'

const Banner = () => {
    const { t } = useTranslation();
    const WhatsApp = () => {
        const phoneNumber = '+966533046533';
        const message = 'Hello, Al faif';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const handleCall = () => {
        const phoneNumber = '+966533046533';
        window.open(`tel:${phoneNumber}`, '_self');
      };


    return (
        <BannerWrapper id='home'>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={5} md={12} className='position-relative'>
                        <Box className='banner-content-wrapper'>
                            <Box>
                                <BannerHeading className='silverColor banner-heading' size='80px' lh="80px">{t('banner_heading1')} </BannerHeading>
                                <BannerHeading className='silverColor banner-heading' size='80px' lh="75px">{t('banner_heading2')} </BannerHeading>
                                <BannerHeading className='silverColor banner-heding-sub' size='48px' lh="45px">{t('banner_heading3')} </BannerHeading>
                                <BannerHeading className='silverColor banner-heding-sub' size='48px' lh="50px">{t('banner_heading4')} </BannerHeading>
                            </Box>
                            <Box className='banner-text'>
                                <TextStyled size="24px" color="var(--lightBlue)">{t('banner_text')}</TextStyled>
                                <SpanStyled className='d-flex align-items-center gap-2'>
                                    <ContactButton icon={ArrowRight} label={t('Get_Started')} onClick={handleCall} />
                                </SpanStyled>
                            </Box>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </BannerWrapper>
    )
}

export default Banner
