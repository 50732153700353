import styled from "styled-components";
import { BannerBg } from "../../Styled/AllImages";

export const BannerWrapper = styled.section`
    background-image: url(${BannerBg});
    width: 100%;
    height: 100vh;
    min-height:600px;
    background-size: cover !important;
    background-position: top !important;
    display: flex;
    align-items: center;
    position: relative;
    .banner-content-wrapper{
        display: flex;
        align-items: center;
        margin-top: 65px;
        margin-left:80px;
        flex-wrap: wrap;
        gap:30px;
        .main-heading{
            line-height: 55px;
            margin-bottom: 10px;
        }

        
    }
`