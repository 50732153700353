import styled from 'styled-components'
import { Box, HeadingStyled, SpanStyled, TextStyled } from './Elements';
import { t } from 'i18next';


export const sliceProps = (props, sub) => {
    const { children, ...otherProps } = props;
    sub.forEach(prop => delete otherProps[prop]);
    return otherProps;
}

export const ButtonWithIcon = (props) => {
    const unwantedProps = ['icon', 'label', 'bgBtn', 'clName', 'clNameMain'];
    return (
        <ButtonWrapper {...sliceProps(props, unwantedProps)} className={`d-flex align-items-center ${props.clNameMain ? props.clNameMain : ''}`}>
            <button {...sliceProps(props, unwantedProps)} className={` ${props.clName ? props.clName : ''} ${props.bgBtn ? props.bgBtn : ''} ${!props.icon ? 'Btn-icon' : ''} `} autoComplete='false'>
                {props.icon && (
                    <props.icon width='20px' />
                )}
                <SpanStyled size='14px'>{props.label ? props.label : ''}</SpanStyled>
            </button>
        </ButtonWrapper>
    )
}
export const TextButton = (props) => {
    const unwantedProps = ['icon', 'label', 'bgBtn', 'clName', 'clNameMain'];
    return (
        <TextButtonWrapper {...sliceProps(props, unwantedProps)} className={`d-flex align-items-center btn-text ${props.clNameMain ? props.clNameMain : ''}`}>
            <button {...sliceProps(props, unwantedProps)} className={`${props.clName ? props.clName : ''} ${props.bgBtn ? props.bgBtn : ''} ${!props.icon ? 'Btn-icon' : ''} `} autoComplete='false'>
                {props.icon && (
                    <props.icon width='20px' />
                )}
                <SpanStyled dir='ltr' size='14px'>{props.label ? props.label : ''}</SpanStyled>

            </button>
        </TextButtonWrapper>
    )
}
const TextButtonWrapper = styled.div`

.btn-text {
    background: var(--gradient);
    padding: 8px 12px;
    border-radius: 8px;
    
}
& button{
    background:none;
    border:none;

    & span{
        color:var(--white);
        font-family:var(--arRegular);
    }
}
`

export const ContactButton = (props) => {
    const unwantedProps = ['icon', 'label'];
    return (
        <ContactWrapper {...sliceProps(props, unwantedProps)}>
            <button className={`btn gap-3 ${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                <HeadingStyled color='var(--white)' size='16px' weight='600'>{props.label ? props.label : ''}</HeadingStyled>
                {props.icon && (
                    <props.icon width='22px' />
                )}
            </button>
        </ContactWrapper>
    )
}
export const ContactButton2 = (props) => {
    const unwantedProps = ['icon', 'label'];
    return (
        <ContactWrapper2 {...sliceProps(props, unwantedProps)}>
            <button {...sliceProps(props, unwantedProps)} className={`btn white gap-3 ${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                {props.icon && (
                    <props.icon width='22px' />
                )}
                <HeadingStyled color='' size='16px' weight='600'>{props.label ? props.label : ''}</HeadingStyled>

            </button>
        </ContactWrapper2>
    )
}
export const InputWithIcon = (props) => {
    const unwantedProps = ['icon', "label"];
    return (
        <InputStyledWrap className='position-relative' >
            <InputStyled {...sliceProps(props, unwantedProps)} className={`w-100 ${!props.icon ? 'no-icon' : ''}`} autoComplete='false' />
            {props.icon && (
                <props.icon width='18px' className='position-absolute input-svg' />
            )}
        </InputStyledWrap>
    )
}
export const TextWithIcon = (props) => (
    <TextWithStyledWrapper color={props.color} className={`d-flex align-items-center btn-text `}>
        <Box className='mx-2 d-flex align-items-center justify-content-center contact-svg'>
            <props.icon width='20px' height='20px' />
        </Box>
        <Box className='title-container'>
            <a href={props.href ? props.href : '#!'} className='mb-0 contact-text' size={props.headingSize ? props.headingSize : '14px'}
                color={!props.alsotextColor ? 'var(--text)' : 'var(--text)'}
                dangerouslySetInnerHTML={{ __html: props.title }}>
            </a>
            {props.desc ?
                <TextStyled className='text-uppercase' color={'var(--text)'} size='14px' >
                    {props?.desc}
                </TextStyled>
                : null}
        </Box>
    </TextWithStyledWrapper>
)

export const TextWithStyledWrapper = styled.div`
  svg {
      [stroke] {
          stroke: ${({ color }) => color ? color : ''};
      }
  }
`
export const InputStyled = styled.input`
    background:var(--bgColor);
    border: none;
    border-radius: 8px;
    height: 43px;
    &:not(.no-icon) {
        padding-left: 50px;
    }
    &.no-icon {
        padding-left: 18px;
    }
    padding-right: 18px;
    outline: none;
    &,&::placeholder {
        font-size: 14px;
        color: var(--textPalceholder);
    }
`
export const InputStyledWrap = styled.div`
    border-radius: 8px;
    background: var(--white);
    svg {
    left: 13px;
    top: 12px;
    height: 20px;
    }
`

export const ContactWrapper = styled.div`
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 45px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s linear;
    z-index: 1;
    position: relative;
    text-transform: uppercase;
    background: ${props => props.bgColor ? props.bgColor : "var(--darkBlue)"};
    padding:6px 20px;
    &:hover{
    opacity:0.8;
    }
}

.ion-icon {
    font-size: 2em;
    transition: all 0.5s linear;
}



    
`
export const ContactWrapper2 = styled.span`

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    // min-width: 220.52px;
    // min-height: 46px;
    font-family: calibri;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.5s linear;
    z-index: 1;
    position: relative;
}

.ion-icon {
    font-size: 2em;
    transition: all 0.5s linear;
}

.btn:hover .ion-icon {
    transform: rotate(-90deg);

}

div {
    position: relative;
}

.white {
    /* background: #0ebac5; */
    background: none;
    color: black;
    & div{
        color: var(--white);
        transition: all 600ms ease;

    }
}

.white::before {
    content: "";
    display: block;
    width: 46px;
    height: 100%;
    background: var(--silverColor);
    position: absolute;
    border-radius: 10px;
    left: 0;
    z-index: -1;
    transition: all 600ms ease;
}

.white:hover::before {
    width: 100%;
}
.white:hover{
    & div{
        color:var(--darkColor)
    }
}

.white:hover .ion-icon {
    transform: translateX(10px);
}
    
    
`
export const ButtonWrapper = styled.div`
button {
    height: 45px;
    border: none;
    padding: 10px 18px;
    border-radius: 8px;
    background: var(--white);
    transition: all 0.4s ease-in-out;
    align-items: center;
    box-shadow: 0px 8px 16px rgba(15, 54, 149, 0.1);
    & > span {
        font-size: 16px;
        color: var(--lightBlue);
        font-family: var(--semiBold);
    }
    &:hover {
        transform: scale(1);
        background: var(--darkBlue);
        & svg {
           [fill] {
            fill:var(--white);
            }
        }

        & span {
            color: var(--white);
        }
}
  
`