import React from 'react'
import { FooterWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, SpanStyled } from '../Styled/Elements'
import { Instagram, Linkedin, Logo, LogoAr, Ministry, Saudi, Twitter } from '../Styled/AllImages'
import { NavDataConfig } from '../data/Data'
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir() === 'rtl';
    const FowlWeb = () => {
        window.open('https://websites.fowl.sa/', '_blank');
    };
    const date_year = new Date();
    const socialIcon = [
        {
            img: <Twitter />,
            link: 'https://x.com/altaif2024?s=21&t=Wq0RMCoJloRUNeSTcfLPrg',
        },

        {
            img: <Instagram />,
            link: 'https://www.instagram.com/eltayef.sa/profilecard/?igsh=MWZ4c3Y1dmw0dXpreg==',
        },
        {
            img: <Linkedin />,
            link: 'https://www.snapchat.com/add/eltayef.sa?share_id=1vRxAMusgk4&locale=ar-AE',
        },

    ];

    return (
        <FooterWrapper className='container-fluid'>
            <Container>
                <Row className='footer-row align-items-center'>
                    <Col lg={2} md={12} sm={12} >
                        <Box className='f-logo-wrap'>
                            <Box className='footer-logo' onClick={() => navigate('/')}>
                                {isRTL ? <LogoAr /> : <Logo />}
                            </Box>
                        </Box>
                    </Col>
                    <Col lg={7} md={12} sm={12} >
                        <Box className='d-flex flex-column justify-content-center align-items-center'>
                            <Box className='footer-list'>
                                {NavDataConfig.map((item, key) => (
                                    <Box key={key} className='footer-nav-items d-flex gap-2'>
                                        <Link to={item.to} spy={true} smooth={true} offset={-60} duration={500}>{t(item.label)}</Link>
                                    </Box>
                                ))}
                            </Box>
                            <Box className='text-center'>
                                <SpanStyled className='footer-op' size='14px' color='var(--lightBlue)' transform='capitalize'>{t('AllRightReserved')} &copy; {date_year.getFullYear()} </SpanStyled>
                                <SpanStyled className='footer-op' size='14px' color='var(--lightBlue)' transform='capitalize'>
                                    <SpanStyled className='footer-op fowl' size='14px' family="var(--bold)"> {t('Al_Taif')}</SpanStyled>
                                </SpanStyled>
                                <HeadingStyled className='footer-op' size='13px' color='var(--lightBlue)' transform='capitalize'>{t('Developed_by')}
                                    <SpanStyled className='fowl footer-op' color='var(--darkBlue)' size='14px' family="var(--bold)" onClick={FowlWeb}> {t('Fowl')}</SpanStyled>
                                </HeadingStyled>
                            </Box>
                        </Box>
                    </Col>
                    <Col lg={3} md={12} sm={12} >

                        <Box className='d-flex flex-column align-items-center'>
                            <Box className='d-flex align-items-center gap-3 mb-2 footer-icons'>
                                <Ministry />
                                <Saudi />
                            </Box>
                            <Box className='d-flex align-items-center gap-3 social-icon'>
                                {
                                    socialIcon.map((list, key) => (
                                        <Box key={key} >
                                            <a href={list.link} target="_blank" rel="noopener noreferrer">
                                                {list.img}
                                            </a>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </FooterWrapper>
    )
}

export default Footer