import React from 'react'
import { AchievementWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, MainHeading, SpanStyled, SubHeading, TextStyled } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { About2, ArrowRight, Phone } from '../../Styled/AllImages'

import { ButtonWithIcon, ContactButton } from '../../Styled/CustomElements'

const Achievement = () => {
    const { t } = useTranslation();
    const WhatsApp = () => {
        const phoneNumber = '+966533046533';
        const message = 'Hello, Al faif';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const handleCall = () => {
        const phoneNumber = '+966533046533';
        window.open(`tel:${phoneNumber}`, '_self');
      };
    return (
        <AchievementWrapper className='common-space'>
            <Container>
                <Box className='bg-added'>
                    <Row className='about-rows'>
                        <Col lg={5} md={4}>
                            <Box className='about-2'>
                                <LazyImage src={About2} />
                            </Box>
                        </Col>
                        <Col lg={7} md={8}>
                            <Box className='content-about-2'>
                                <HeadingStyled size="32px" color='var(--white)'>{t('Pathways_Success')}</HeadingStyled>
                                <TextStyled size="24px" color='var(--white)' >{t('Pathways_Success_content')}</TextStyled>
                                <SpanStyled className='d-flex align-items-center gap-2'>
                                    <ContactButton bgColor="#25D366" icon={ArrowRight} label={t('Get_Started')} onClick={WhatsApp} />
                                    <ButtonWithIcon clName='hover-color header-btn d-flex gap-2' icon={Phone} label='+966533046533' onClick={handleCall} />
                                </SpanStyled>
                            </Box>
                        </Col>
                    </Row>
                </Box>
                {/* <Row ref={ref}>
                    {
                        achieveData.map((data, index) => (

                            <Col key={index} lg={4} md={12}>
                                <AnimateGroup play={inView}>
                                    <Animate

                                        delay={data.delay}
                                        sequenceIndex={data.sequenceIndex}
                                        start={start}
                                        end={end}
                                    >
                                        <Box className='achieve-box'>
                                            <MainHeading dir="ltr" className='main-heading themeColor'> {t(data.heading)} + </MainHeading>
                                            <TextStyled color="var(--white)">{t(data.text)}</TextStyled>
                                        </Box>
                                    </Animate>
                                </AnimateGroup>
                            </Col>
                        ))
                    }
                </Row> */}
            </Container>

        </AchievementWrapper>
    )
}

export default Achievement