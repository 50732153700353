import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, SpanStyled, TextStyled } from '../../Styled/Elements'
import { ProjectOtherData } from '../../data/Data'
import { ChooseWapper } from './styled'
import { useTranslation } from 'react-i18next'
import {  ExperianceImg } from '../../Styled/AllImages'

const Choose = () => {
    const { t} = useTranslation();
    
    return (

        <ChooseWapper id='choose'>
            <Container className='comparing'>
                <Row className='align-items-center'>
                    <Col lg={7} md={12}>
                        <Box className='Choose-digital'>
                            <HeadingStyled className='experiance-heading' color='var(--darkBlue)' size="40px" > {t('Why_Choose_Us_content')} <SpanStyled color='var(--lightBlue)' >{t('Why_Choose_Us_content2')}</SpanStyled>  </HeadingStyled>
                            <Box className='experiance-detail'>
                                {
                                    ProjectOtherData.map((list, key) => (
                                        <Box key={key} className='project-wapper'>
                                            <Box className='project-content compaire'>
                                                {list.img}
                                                <Box>
                                                    <HeadingStyled color='var(--themeColor)' size='18px' weight='normal'>{t(list.heading)}</HeadingStyled>
                                                    <TextStyled  size="14px">{t(list.text)}</TextStyled>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Col>
                    <Col lg={5} md={12} >
                        <Box className='porject-img'>
                            <LazyImage src={ExperianceImg} />
                        </Box>
                    </Col>
                </Row>
            </Container>
        </ChooseWapper>
    )
}

export default Choose