import styled from "styled-components";
import { AboutBg, AchieveBg } from "../../Styled/AllImages";

export const AboutWrapper = styled.section`

    width: 100%;
    height: 100%;
    background-image: url(${AboutBg});
    background-size: cover !important;
    .about-content{
        position: relative;
        top: -50px;
        width:100%;
        background-color: var(--white);
        border-radius: 16px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom;
    }
    .about-rows{
        position:relative;
        align-items: center;
        justify-content: center;
    }

`
export const AchievementWrapper = styled.div`
    .bg-added{
        position:relative;
        background-color:var(--lightBlue);  
        background-image: url(${AchieveBg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius:8px;
        height: 300px;
        display:flex;
        align-items: center;
        justify-content: center;
    }
     .about-rows{
        position:relative;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
        .about-2{
            position: relative;
            top: -31px;
            right:40px;
            text-align: end;
        }
`