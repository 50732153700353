import styled from "styled-components";
import { FooterBg } from "../Styled/AllImages";

export const FooterWrapper = styled.footer`
    padding:15px 0;
    background-image: url(${FooterBg});
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat;
.footer-list{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    & a{
        color: var(--white);
        font-family: var(--semibold);
        &:hover{
        }
    }
    
}
.footer-list a{
    color:var(--darkBlue);
    padding-bottom: 8px;
    position: relative;
    font-size:14px;
    font-family: var(--regular);
    font-weight: 400;
    text-transform: uppercase;
    &:hover, &.active, &:focus{
        color:var(--lightBlue);
        font-family: var(--semibold);
        font-weight: 400;
        &::before, &.active::before{
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            width: 15px;
            height: 3px;
            background: var(--lightgreen);
        }
    }
}
.footer-revised{
    position:relative;
    &::before{
        content:' ';
        position:absolute;
        top:0;
        right:0;
        left:-20px;
        margin:auto;
        width:101%;
        height:1px;
        background-color:#8C919D;
    }
    padding:25px 25px 25px 25px;
}
.fowl{
    cursor: pointer;
}
.f-logo-wrap{
    display:flex;
    align-items:start;
    justify-content:start;
    cursor:pointer;
}
    .footer-icons{
        & svg{
        width:140px !important;
        }
    }

`