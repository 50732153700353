import styled from 'styled-components'


export const HeaderContainer = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 110;
    width: 100%;
    padding:14px 0;
    transition: all 0.5s ease-in-out;
    .nav-bar{
        display:flex;  
        align-items: center;
        justify-content: space-between;
    }
    .nav-bar-list{
        display:flex;  
        align-items: center;
        gap: 35px;
    }
    .nav-bar-list a{
        color:var(--darkBlue);
        padding-bottom: 10px;
        position: relative;
        font-size:16px;
        font-family: var(--semiBold);
        text-transform: uppercase;
        &:focus, &:hover, &.active{
            color:var(--lightBlue);
            font-family: var(--semiBold);
            &::before, &.active::before{
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                width: 100%;
                height: 3px;
                background: var(--lightBlue);
            }
            
        }
    }
    .logo{
        cursor:pointer;
        transition: all 0.5s ease-in-out;
        svg{
            width:150px;
            transition: all 0.5s ease-in-out;
        }
    }
    &.sticky{
        background: var(--white);
        box-shadow: 0px 3px 10px #9e9e9ec2;
        z-index: 200;

        // .header-btn{
        //     background: var(--darkBlue);
        //     & svg {
        //     [fill] {
        //         fill:var(--white);
        //         }
        //     }
        
        //     & span {
        //         color: var(--white);
        //     }
        // }
    }
    .toggleBtn{
        display: none;
    }
}
`