import React from 'react'
import ContactForm from './ContactForm'
import { ContactWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { TextButton, TextWithIcon } from '../../Styled/CustomElements'
import { Box, MainHeading, TextStyled, } from '../../Styled/Elements'
import { Email, Location, Phone, } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'

const Contact = () => {
  const WhatsApp = () => {
    const phoneNumber = '+966533046533';
    const message = 'Hello, Al faif';
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  };
  const handleCall = () => {
    const phoneNumber = '+966533046533';
    window.open(`tel:${phoneNumber}`, '_self');
  };
  // const Map = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.062699793756!2d46.758571010944486!3d24.69037127794914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f06cf7baf3ce9%3A0xddcab026a24890d3!2sAhmad%20Ibn%20Ghalib%2C%20Riyadh%20Saudi%20Arabia!5e0!3m2!1sen!2s!4v1714735326167!5m2!1sen!2s"
  const { t } = useTranslation();

  return (
    <ContactWrapper id='contact' className='common-space'>
      <Container>
        <Box className='containerContact'>
          <Row>
            <Box className='heading-content'>
              <MainHeading className='main-heading' color="var(--white)">{t("Let_Talk")} </MainHeading>
              <TextStyled className='contact-p' size='32px' family='var(--bold)' color="var(--white)"> {t('content_text')} </TextStyled>
            </Box>
          </Row>
          <Row>
            <Col lg={12} md={12} className='contact-Us'>

              <Box className='contect-with-us'>
                <Box className='contact-info'>
                  <TextButton icon={Phone} label='+966533046533' onClick={handleCall} />
                  <TextWithIcon icon={Email} title='Info@eltayef.sa' href="mailto:Info@eltayef.sa" />
                </Box>
                <ContactForm />
              </Box>
            </Col>
          </Row>
        </Box>
      </Container>
    </ContactWrapper>
  )
}

export default Contact