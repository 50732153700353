import React from 'react'
import { AboutWrapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import { About1, ArrowRight } from '../../Styled/AllImages'
import Achievement from './Achievement'
import { ContactButton } from '../../Styled/CustomElements'


const About = () => {
    const { t } = useTranslation();
    const WhatsApp = () => {
        const phoneNumber = '+966533046533';
        const message = 'Hello, Al faif';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };

    return (
        <>
            <AboutWrapper id='about'>
                <Container>
                    <Row className='about-rows'>
                        <Col lg={6} md={12}>
                            <Box className='about-content-wrapper'>
                                <MainHeading className='main-heading themeColor' transform="capitalize">{t('Premier_Source')} </MainHeading>
                                <MainHeading className='main-heading mb-3' color="var(--lightBlue)" transform="capitalize"> {t('for_Trained_Workers')} </MainHeading>
                                <TextStyled color='var(--darkBlue)' size="18px">{t('about_content')}</TextStyled>
                                <SpanStyled className='d-flex align-items-center gap-2'>
                                    <ContactButton bgColor="var(--lightBlue)" icon={ArrowRight} label={t('Get_Started')} onClick={WhatsApp} />
                                </SpanStyled>
                            </Box>
                        </Col>
                        <Col lg={6} md={12}>
                            <Box className='text-align'>
                                <LazyImage src={About1} />
                            </Box>
                        </Col>
                    </Row>
                </Container>
            </AboutWrapper >
            <Achievement />
        </>
    )
}

export default About