import { Alimited, Aservice, Asolution, Atimer, Client1, Client10, Client11, Client12, Client13, Client14, Client15, Client16, Client17, Client18, Client19, Client2, Client20, Client21, Client22, Client23, Client24, Client25, Client26, Client27, Client28, Client29, Client3, Client30, Client31, Client32, Client33, Client4, Client5, Client6, Client7, Client8, Client9, Excellence, Experience, Expertise, Installation1, Installation2, Installation3, Installation4, Installation5, Installation6, Installation7, Limited, Mainten1, Mainten10, Mainten2, Mainten3, Mainten4, Mainten5, Mainten6, Mainten7, Mainten8, Mainten9, Service, Solution, Timer } from "../Styled/AllImages";
import { LazyImage } from "../Styled/Elements";


export const NavDataConfig = [
    {
        to: 'home',
        label: 'Home',
    },
    {
        to: 'about',
        label: 'About',
    },
    {
        to: 'service',
        label: 'Services',
    },
    {
        to: 'choose',
        label: 'Why_Choose_Us',
    },
    // {
    //     to: 'gallery',
    //     label: 'Portfolio',
    // },
    {
        to: 'client',
        label: 'Client',
    },

    {
        to: 'contact',
        label: 'Contact_Us',
    },
]


export const maintenanceData = [
    {
        text: 'Building_Maintenance',
        img: Mainten1,
        delay: "0.3",
        sequenceIndex: '0',
    },
    {
        text: 'Air_Conditioning_Maintenance',
        img: Mainten5,
        delay: "0.4",
        sequenceIndex: '1',
    },
    {
        text: 'Restaurant_Maintenance',
        img: Mainten2,
        delay: "0.5",
        sequenceIndex: '2',
    },
    {
        text: 'Electrical_Maintenance',
        img: Mainten6,
        delay: "0.6",
        sequenceIndex: '3',
    },
    {
        text: 'Home_Appliance_Maintenance',
        img: Mainten7,
        delay: "0.7",
        sequenceIndex: '4',
    },
    {
        text: 'Plumbing_Maintenance',
        img: Mainten4,

        delay: "0.8",
        sequenceIndex: '4',
    },
    {
        text: 'Compressor_Maintenance',
        img: Mainten8,
        delay: "0.9",
        sequenceIndex: '5',
    },
    {
        text: 'Mainten9',
        img: Mainten9,
        delay: "1.0",
        sequenceIndex: '6',
    },
    {
        text: 'Garden_Maintenance',
        img: Mainten3,
        delay: "1.1",
        sequenceIndex: '7',
    },
    {
        text: 'Mainten10',
        img: Mainten10,
        delay: "1.1",
        sequenceIndex: '7',
    },

]
export const operationData = [
    {
        text: 'Installation1',
        img: Installation1,
        delay: "0.3",
        sequenceIndex: '1',
    },
    {
        text: 'Installation2',
        img: Installation2,
        delay: "0.4",
        sequenceIndex: '2',
    },
    {
        text: 'Installation3',
        img: Installation3,
        delay: "0.5",
        sequenceIndex: '3',
    },
    {
        text: 'Installation4',
        img: Installation4,
        delay: "0.6",
        sequenceIndex: '4',
    },
    {
        text: 'Installation5',
        img: Installation5,
        delay: "0.7",
        sequenceIndex: '5',
    },
    {
        text: 'Installation6',
        img: Installation6,
        delay: "0.8",
        sequenceIndex: '6',
    },
    {
        text: 'Installation7',
        img: Installation7,
        delay: "0.9",
        sequenceIndex: '7',
    }, {
        text: 'Installation8',
        img: Mainten10,
        delay: "1.1",
        sequenceIndex: '8',
    },

]


export const ProjectOtherData = [
    {
        img: <LazyImage src={Limited} />,
        heading: "Limited_Experience",
        text: "Limited_Experience_content",
        delay: "0.4",
        sequenceIndex: '0',
    },
    {
        img: <LazyImage src={Service} />,
        heading: "Fragmented_Services",
        text: "Fragmented_Services_content",
        delay: "0.6",
        sequenceIndex: '1',
    },
    {
        img: <LazyImage src={Solution} />,
        heading: "Generic_Solutions",
        text: "Generic_Solutions_content",
        delay: "0.9",
        sequenceIndex: '2',
    },
    {
        img: <LazyImage src={Timer} />,
        heading: "Unreliable_Performance",
        text: "Unreliable_Performance_content",
        delay: "1.2",
        sequenceIndex: '3',
    },
]



export const ClientData = [
    {
        logo: <Client1/>,
        delay: "0.3",
        sequenceIndex: '0',

    },
    {
        logo: <Client2/>,
        delay: "0.4",
        sequenceIndex: '1',
    },
    {
        logo: <Client3/>,
        delay: "0.5",
        sequenceIndex: '2',
    },
    {
        logo: <Client4/>,
        delay: "0.6",
        sequenceIndex: '3',
    },
    {
        logo: <Client5/>,
        delay: "0.7",
        sequenceIndex: '4',
    },
    {
        logo: <Client6/>,
        delay: "0.8",
        sequenceIndex: '5',
    },
    {
        logo: <Client7/>,
        delay: "0.9",
        sequenceIndex: '6',
    },
    {
        logo: <Client8/>,
        delay: "1.0",
        sequenceIndex: '6',
    },
    {
        logo: <Client9/>,
        delay: "1.1",
        sequenceIndex: '8',
    },
    {
        logo: <Client10/>,
        delay: "1.2",
        sequenceIndex: '9',
    },
    {
        logo: <Client11/>,
        delay: "1.3",
        sequenceIndex: '10',
    },
    {
        logo: <Client12/>,
        delay: "1.4",
        sequenceIndex: '11',
    },
    {
        logo: <Client13/>,
        delay: "1.5",
        sequenceIndex: '12',
    },
    // {
    //     logo: Client14,
    //     delay: "1.5",
    //     sequenceIndex: '13',
    // },
    // {
    //     logo: Client15,
    //     delay: "1.5",
    //     sequenceIndex: '14',
    // },
]


export const achieveData = [
    {
        heading: "2000",
        text: "Professional_worker",
        delay: "0.6",
        sequenceIndex: '0',
    },
    {
        heading: "60",
        text: "Professional_employees",
        delay: "0.8",
        sequenceIndex: '1',
    },
    {
        heading: "5",
        text: "Satisfied_clients",
        delay: "1.0",
        sequenceIndex: '2',
    },
]