import React, { useState } from 'react'
import { ServiceWrapper } from './Styled'
import { Col, Container, Row, } from 'react-bootstrap'
import { Box, HeadingStyled, MainHeading, SpanStyled, SubHeading } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { maintenanceData, operationData } from '../../data/Data'

import { useInView } from 'react-intersection-observer'
import { Animate, AnimateGroup } from 'react-simple-animate'

const Service = () => {
    const { t, i18n } = useTranslation();
    const [serviceType, setServiceType] = useState('Maintenance');
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const isRTL = i18n.dir() === 'rtl';
    const start = { opacity: 0, transform: "translateY(-15px)" }
    const end = { opacity: 1, transform: "translateY(0)" };

    return (
        <ServiceWrapper id='service' className='common-space service-bg'>
            <Container>
                <Row ref={ref} className='align-items-center justify-content-center' >
                    <Box className='heading-content'>
                        <MainHeading className='main-heading' color='var(--darkBlue)' transform="capitalize"> {t('EMPOWER_YOUR_BUSINESS1')}, <SpanStyled color='var(--lightBlue)'> {t('EMPOWER_YOUR_BUSINESS2')} </SpanStyled> </MainHeading>
                    </Box>
                    <Box className='product-wrapper'>
                        <Box className='product-wrapper-tabs'>
                            <SubHeading
                                size='18px'
                                onClick={() => setServiceType("Maintenance")}
                                className={`${serviceType === "Maintenance" ? "activeTab" : ""}`}
                            >
                                {t('Maintenance')}
                            </SubHeading>
                            <SubHeading
                                size='18px'
                                onClick={() => setServiceType("Operations")}
                                className={`${serviceType === "Operations" ? "activeTab" : ""}`}
                            >
                                {t('Operations')}
                            </SubHeading>
                        </Box>
                    </Box>
                </Row>

                {serviceType === "Maintenance" && (
                    <Row ref={ref} className='tab-active-row justify-content-center'>
                        {
                            maintenanceData.map((item, Index) => (
                                <Col lg={4} md={6} sm={12}>
                                    <AnimateGroup play={inView}>
                                        <Animate
                                            delay={item.delay}
                                            sequenceIndex={item.sequenceIndex}
                                            start={start}
                                            end={end}
                                        >
                                            <Box key={Index} className='activeTab-box'>
                                                <Box className='active-tab-content service-bg' style={{ background: `url(${item.img})` }}>
                                                    <HeadingStyled className='service-text' size='16px' color='var(--white)' transform='math-auto'> {t(item.text)}</HeadingStyled>
                                                </Box>
                                            </Box>
                                        </Animate>
                                    </AnimateGroup>
                                </Col>
                            ))
                        }
                    </Row>
                )}

                {serviceType === "Operations" && (
                    <Row className='position-relative common-space g-3'>
                        {operationData.slice(0, 2).map((item, index) => (
                            <Col lg={6} md={6} sm={12} key={`row1-${index}`}>
                                <AnimateGroup play={inView}>
                                    <Animate
                                        delay={item.delay}
                                        sequenceIndex={item.sequenceIndex}
                                        start={start}
                                        end={end}
                                    >
                                        <Box className='activeTab-box'>
                                            <Box
                                                className='active-tab-content service-bg'
                                                style={{ background: `url(${item.img})` }}
                                            >
                                                <HeadingStyled
                                                    className='service-text'
                                                    size='16px'
                                                    color='var(--white)'
                                                    transform='math-auto'
                                                >
                                                    {t(item.text)}
                                                </HeadingStyled>
                                            </Box>
                                        </Box>
                                    </Animate>
                                </AnimateGroup>
                            </Col>
                        ))}

                        {operationData.slice(2, 5).map((item, index) => (
                            <Col lg={4} md={6} sm={12} key={`row2-${index}`}>
                                <AnimateGroup play={inView}>
                                    <Animate
                                        delay={item.delay}
                                        sequenceIndex={item.sequenceIndex}
                                        start={start}
                                        end={end}
                                    >
                                        <Box className='activeTab-box'>
                                            <Box
                                                className='active-tab-content service-bg'
                                                style={{ background: `url(${item.img})` }}
                                            >
                                                <HeadingStyled
                                                    className='service-text'
                                                    size='16px'
                                                    color='var(--white)'
                                                    transform='math-auto'
                                                >
                                                    {t(item.text)}
                                                </HeadingStyled>
                                            </Box>
                                        </Box>
                                    </Animate>
                                </AnimateGroup>
                            </Col>
                        ))}
                        {operationData.slice(5, 8).map((item, index) => (
                            <Col lg={4} md={6} sm={12} key={`row3-${index}`}>
                                <AnimateGroup play={inView}>
                                    <Animate
                                        delay={item.delay}
                                        sequenceIndex={item.sequenceIndex}
                                        start={start}
                                        end={end}
                                    >
                                        <Box className='activeTab-box'>
                                            <Box
                                                className='active-tab-content service-bg'
                                                style={{ background: `url(${item.img})` }}
                                            >
                                                <HeadingStyled
                                                    className='service-text'
                                                    size='16px'
                                                    color='var(--white)'
                                                    transform='math-auto'
                                                >
                                                    {t(item.text)}
                                                </HeadingStyled>
                                            </Box>
                                        </Box>
                                    </Animate>
                                </AnimateGroup>
                            </Col>
                        ))}
                    </Row>
                )}

            </Container>
        </ServiceWrapper >
    )
}

export default Service